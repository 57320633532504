import { Expose, Transform, Type } from "class-transformer";
import { Airline } from "@/src/airlines/domain/models/airline";
import { IProduct } from "@/src/products/domain/interfaces/product";
import { Money } from "@/src/common/domain/models/money";
import { Brand } from "@/src/common/domain/models/brand";
import { DeliveryInfo } from "@/src/common/domain/models/delivery";
import { Dimensions } from "@/src/products/domain/models/product";
import { ImagesCollection } from "@/src/common/domain/models/image";
import { ProductVariant } from "@/src/products/domain/models/product_variant";
import dayjs from "dayjs";
import { FamilyProductColor } from "@/src/products/domain/models/family_product_color";

export enum BagType {
  SMALL = "SMALL",
  CABIN = "CABIN",
  EXTRA = "EXTRA"
}

export class BagSize {
  width!: number;
  height!: number;
  length!: number;
}

export class BagSizes {
  @Type(() => BagSize)
  small!: BagSize;
  @Type(() => BagSize)
  cabin!: BagSize;
  @Type(() => BagSize)
  extra!: BagSize;
}

export class BagFit {
  small!: Array<Airline>;
  cabin!: Array<Airline>;
  extra!: Array<Airline>;
}

export class Bag implements IProduct {
  id!: number;
  asin!: string;
  availabilityMessage?: string;
  availabilityType?: string;
  color?: string;
  features: Array<string> = [];
  manufacturer?: string;
  model?: string;
  parentAsin?: string | null;
  ratingsCount!: number;
  ratingsStars!: number;
  title!: string;
  @Type(() => ProductVariant)
  family?: Array<ProductVariant>;
  @Type(() => FamilyProductColor)
  familyByColors!: Array<FamilyProductColor>;
  @Type(() => Money)
  price!: Money;
  @Type(() => Money)
  basePrice!: Money;
  @Type(() => Money)
  savings!: Money;
  savingsPercentage!: number;
  @Type(() => Brand)
  brand?: Brand;
  @Type(() => DeliveryInfo)
  deliveryInfo?: DeliveryInfo;
  @Type(() => Dimensions)
  dimensions?: Dimensions;
  @Type(() => ImagesCollection)
  images!: ImagesCollection;
  @Type(() => BagFit)
  bagFit?: BagFit;
  hasPrime = false;
  @Type(() => ProductVariant)
  material?: string;
  size?: string;
  @Transform(({ value }) => value.toUpperCase(), { toClassOnly: true })
  flightUse?: BagType;
  @Expose({ name: "lastUpdate" })
  @Transform(({ value }) => dayjs(value, "YYYY-MM-DD"), { toClassOnly: true })
  @Transform(({ value }) => (value as dayjs.Dayjs).format("YYYY-MM-DD"), { toPlainOnly: true })
  lastUpdate?: dayjs.Dayjs;

  get formattedLastUpdate(): string | null {
    if (this.lastUpdate && this.lastUpdate.isValid()) {
      return this.lastUpdate.format("D MMMM YYYY");
    } else {
      return null;
    }
  }
}
