import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/src/common/ui/store";
import { IProductsCartStorage, IProductWithQuantity } from "@/src/cart/ui/domain/products_cart_storage";
import { IProduct } from "@/src/products/domain/interfaces/product";
import { locator } from "@/ioc/index";
import { ICartStorageRepository } from "@/src/cart/domain/interfaces/cart_storage_repository";
import { TYPES } from "@/ioc/types";
import { plainToClass } from "class-transformer";
import { Bag } from "@/src/products/domain/models/bag";
import { AirlinesByFlightUse, IncludedExcludedAirlines } from "@/src/airlines/domain/models/airlines_by_flight_use";

export const productCartPreloadState = () => {
  const storageRepository = locator.get<ICartStorageRepository>(TYPES.ICartStorageRepository);
  const storageData = storageRepository.load();
  return {
    products: storageData.products.map(({ quantity, product, airlines }) => ({
      quantity,
      product: plainToClass(Bag, product),
      airlines: plainToClass(AirlinesByFlightUse, airlines)
    }))
  };
};

const initialState = (): IProductsCartStorage => ({
  products: [],
  initialized: false
});

export const PRODUCT_SLICE_NAME = "products_cart.slice";

const productsCartSlice = createSlice({
  name: PRODUCT_SLICE_NAME,
  initialState: initialState(),
  reducers: {
    syncFromStorage: (state) => {
      state.products = productCartPreloadState().products;
      state.initialized = true;
    },
    addProduct: (state, action: PayloadAction<{ product: IProduct; airlines: AirlinesByFlightUse }>) => {
      const { product: newProduct, airlines } = action.payload;
      const productFound = state.products.find((prod) => prod.product.id === newProduct.id);
      if (productFound) {
        productFound.quantity += 1;
        state.products = Object.assign(state.products, {});
      } else {
        const quantityProduct: IProductWithQuantity = { quantity: 1, product: newProduct, airlines };
        state.products = [...state.products, quantityProduct];
      }
    },
    setNewQuantity(state, action: PayloadAction<{ productId: number; quantity: number }>) {
      const { productId, quantity } = action.payload;
      const productFound = state.products.find((prod) => prod.product.id === productId);
      if (productFound) {
        productFound.quantity = quantity;
        state.products = Object.assign(state.products, {});
      }
    },
    deleteProduct: (state, action: PayloadAction<number>) => {
      state.products = state.products.filter((prodQuantity) => prodQuantity.product.id !== action.payload);
    }
  }
});

function selectProductsCartBase(state: RootState) {
  return state.productsCart;
}

export const getCartProducts = createSelector([selectProductsCartBase], (slice) => slice.products);
export const getCartProductsAmount = createSelector([getCartProducts], (products) =>
  products.reduce((acc, prodQuantity) => {
    acc += prodQuantity.quantity;
    return acc;
  }, 0)
);
export const getCartTotalPrice = createSelector([getCartProducts], (products) => {
  const totalPrice = products.reduce((acc, quantityProd) => {
    acc += parseFloat(quantityProd.product.price.amount) * quantityProd.quantity;
    return acc;
  }, 0);
  const productsCurrency = products.length ? products[0].product.price.currency : "GBP";
  return Intl.NumberFormat("en", { style: "currency", currency: productsCurrency }).format(totalPrice);
});

export const { addProduct, setNewQuantity, deleteProduct, syncFromStorage } = productsCartSlice.actions;
export default productsCartSlice.reducer;
