import { Airline } from "@/src/airlines/domain/models/airline";
import { Type } from "class-transformer";

export class IncludedExcludedAirlines {
  @Type(() => Airline)
  included!: Array<Airline>;
  @Type(() => Airline)
  excluded!: Array<Airline>;
}

export class AirlinesByFlightUse {
  @Type(() => IncludedExcludedAirlines)
  small!: IncludedExcludedAirlines;
  @Type(() => IncludedExcludedAirlines)
  cabin!: IncludedExcludedAirlines;
  @Type(() => IncludedExcludedAirlines)
  extra!: IncludedExcludedAirlines;
}
