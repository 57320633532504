import { IHttpClient } from "../../domain/interfaces/http_client";
import { DocumentNode } from "graphql";
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { inject, injectable } from "inversify";
import { IEnvVars } from "../../domain/interfaces/env_vars";
import { TYPES } from "@/ioc/types";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";

@injectable()
export class HttpClient implements IHttpClient {
  private client: ApolloClient<NormalizedCacheObject>;

  constructor(@inject(TYPES.IEnvVars) envVars: IEnvVars) {
    this.client = envVars.authCredentialsEnabled
      ? new ApolloClient({
          cache: new InMemoryCache(),
          uri: envVars.serverUrl,
          headers: {
            authorization: `Basic ${envVars.authString}`
          },
          queryDeduplication: false,
          ssrMode: true,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: "no-cache",
              errorPolicy: "ignore"
            },
            query: {
              fetchPolicy: "no-cache",
              errorPolicy: "all"
            },
            mutate: {
              errorPolicy: "all"
            }
          }
        })
      : new ApolloClient({
          cache: new InMemoryCache(),
          uri: envVars.serverUrl,
          queryDeduplication: false,
          ssrMode: true,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: "no-cache",
              errorPolicy: "ignore"
            },
            query: {
              fetchPolicy: "no-cache",
              errorPolicy: "all"
            },
            mutate: {
              errorPolicy: "all"
            }
          }
        });
  }

  async mutate<T extends { errors?: Array<string> }, V = any>(mutation: DocumentNode, variables?: V): Promise<T | null | undefined> {
    try {
      const { data, errors } = await this.client.mutate<T>({
        mutation,
        variables
      });
      const hasError = errors && errors.length;
      const hasDataError = data?.errors && data.errors.length;
      if (hasError) {
        throw new Error(errors[0].message);
      } else if (hasDataError) {
        throw new Error(data.errors![0]);
      }
      return data;
    } catch (e) {
      throw e;
    }
  }

  async query<T extends { errors?: Array<string> }, V = any>(
    query: DocumentNode,
    variables?: V,
    fetchPolicy?: FetchPolicy
  ): Promise<T | null | undefined> {
    try {
      const { data, errors } = await this.client.query<T>({
        query,
        variables,
        fetchPolicy
      });
      const hasError = errors && errors.length;
      const hasDataError = data?.errors && data.errors.length;
      if (hasError) {
        throw new Error(errors[0].message);
      } else if (hasDataError) {
        throw new Error(data.errors![0]);
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
}
