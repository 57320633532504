import { IEnvVars } from "../interfaces/env_vars";
import { injectable } from "inversify";

@injectable()
export class EnvVars implements IEnvVars {
  serverUrl: string = `${process.env.NEXT_PUBLIC_API_URL}/s/graphql/` || "localhost:3000/s/graphql/";
  amazonAddUrl: string = process.env.NEXT_PUBLIC_AMAZON_ADD_URL || "";
  amazonAccessKeyId: string = process.env.NEXT_PUBLIC_AMAZON_ACCESS_KEY_ID || "";
  amazonAssociateTag: string = process.env.NEXT_PUBLIC_AMAZON_ASSOCIATE_TAG || "";
  amazonCountryDomain: string = process.env.NEXT_PUBLIC_AMAZON_COUNTRY_DOMAIN || "";
  advertisementLink: string = process.env.NEXT_PUBLIC_ADVERTISEMENT_LINK || "";
  cookiesLink: string = process.env.NEXT_PUBLIC_COOKIES_LINK || "";
  legalAreaLink: string = process.env.NEXT_PUBLIC_LEGAL_AREA_LINK || "";
  privacyLink: string = process.env.NEXT_PUBLIC_PRIVACY_LINK || "";
  termAndConditionsLink: string = process.env.NEXT_PUBLIC_TERM_AND_CONDITIONS_LINK || "";
  customerServiceLink: string = process.env.NEXT_PUBLIC_CUSTOMER_SERVICE_LINK || "";
  gtmTrackingId: string = process.env.NEXT_PUBLIC_GTM_TRACKING_ID || "";
  authCredentialsEnabled: string | boolean = process.env.NEXT_PUBLIC_AUTH_CREDENTIALS_ENABLED || false;
  authString: string = process.env.NEXT_PUBLIC_AUTH_STRING || "";
}
