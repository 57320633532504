import { ICartStorageRepository } from "@/src/cart/domain/interfaces/cart_storage_repository";
import { inject, injectable } from "inversify";
import { TYPES } from "@/ioc/types";
import type { ILocalStorageService } from "@/src/common/domain/interfaces/local_storage_service";
import { IProductsCartStorage } from "@/src/cart/ui/domain/products_cart_storage";

const STORAGE_KEY = "AM_CART";

@injectable()
export class CartStorageRepository implements ICartStorageRepository {
  @inject(TYPES.ILocalStorageService) private localStorageService!: ILocalStorageService;

  sync(storage: IProductsCartStorage): void {
    this.localStorageService.put(STORAGE_KEY, storage);
  }

  load(): Omit<IProductsCartStorage, "initialized"> {
    const cartStorage = this.localStorageService.get<IProductsCartStorage>(STORAGE_KEY);
    return cartStorage ?? { products: [] };
  }
}
