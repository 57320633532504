import { Image } from "@/src/common/domain/models/image";
import { Expose, plainToClass, Transform } from "class-transformer";

export class ProductVariantSize {
  @Expose()
  asin!: string;
  @Expose()
  color!: string;
  @Expose()
  litres?: string;
}

export class ProductVariantColor {
  @Expose()
  asin!: string;
  @Expose()
  size!: string;
  @Expose()
  title!: string;
}

export class ProductVariant {
  asin!: string;
  color?: string;
  title?: string;
  @Transform(({ value }) => {
    if (value?.hasOwnProperty("heightCm")) {
      return `${Math.round(value.heightCm)}cm`;
    } else {
      return value;
    }
  })
  size?: string;
  @Transform(({ value }) => {
    if (value && value?.hasOwnProperty("primary")) {
      return plainToClass(Image, value.primary);
    } else if (value) {
      return plainToClass(Image, value);
    }
  })
  image!: Image;
}
