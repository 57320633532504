import "../common/ioc/index";
import "../common/ui/styles/globals.scss";
import "swiper/scss";
import "swiper/css/pagination";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { NextPage } from "next";
import { Provider } from "react-redux";
import { store, useAppDispatch } from "../common/ui/store";
import { appWithTranslation } from "next-i18next";
import { AMModal } from "@/src/common/ui/components/am_modal/am_modal";
import { syncFromStorage } from "@/src/cart/ui/store/products_cart.slice";
import { useRouter } from "next/router";
import { pushRoute } from "@/src/common/ui/store/router.slice";
import SwiperCore, { Mousewheel, Pagination } from "swiper";
import Head from "next/head";
import { initApp, setShowLoader } from "@/src/common/ui/store/ui.slice";
import { AMMainLoader } from "@/src/common/ui/components/am_main_loader/am_main_loaderl";
import Script from "next/script";
import { locator } from "@/ioc/index";
import { IEnvVars } from "@/src/common/domain/interfaces/env_vars";
import { TYPES } from "@/ioc/types";
import TagManager from "react-gtm-module";

SwiperCore.use([Mousewheel, Pagination]);

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const gtmTrackingId = useMemo(() => locator.get<IEnvVars>(TYPES.IEnvVars).gtmTrackingId, []);
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const handleRouteChangeStart = (url: string, { shallow }: { shallow: boolean }) => {
    store.dispatch(setShowLoader(true));
    store.dispatch(pushRoute(url));
  };

  const handleRouteChangeComplete = () => {
    store.dispatch(setShowLoader(false));
  };

  const handleRouteChangeError = () => {
    store.dispatch(setShowLoader(false));
  };

  useEffect(() => {
    TagManager.initialize({ gtmId: gtmTrackingId });
    store.dispatch(syncFromStorage());
    store.dispatch(initApp());

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Cabin bag finder</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Provider store={store}>
        {
          <>
            <AMMainLoader />
            <AMModal />
            {getLayout(<Component {...pageProps} />)}
          </>
        }
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
