import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { debouncedDispatch, RootState } from "@/src/common/ui/store";
import { ProductFiltersState } from "@/src/products/ui/view_models/product_filters.slice";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import { Page } from "@/src/common/domain/models/page";
import { Bag } from "@/src/products/domain/models/bag";
import type { SearchProductsUseCase } from "@/src/products/domain/use_cases/search_products_use_case";
import type { SearchProductsCountUseCase } from "@/src/products/domain/use_cases/search_products_count_use_case";
import { ProductFilters } from "@/src/products/domain/interfaces/filters";
import { ProductOrderByEnum } from "@/src/products/domain/interfaces/product_order_by";
import { PriceRange } from "@/src/products/domain/models/price_range";
import isEqual from "lodash.isequal";

const ITEMS_PER_PAGE_LIMIT = 24;

const initialState = (): ProductFiltersState => ({
  showEmptyState: false,
  countSearchPromise: null,
  resultCountForFilters: null,
  searchPromise: null,
  searchValue: "",
  currentPage: null,
  orderBy: ProductOrderByEnum.FEATURED,
  filters: {
    flightUse: null,
    airlines: [],
    luggageBrand: [],
    priceRange: undefined,
    previousPriceRangeVal: undefined,
    reviews: null,
    prime: false,
    bagType: [],
    casing: [],
    material: [],
    color: [],
    size: null,
    volume: [],
    weight: [],
    numberWheels: []
  },
  productsPage: new Page<Bag>(Bag),
  priceRangeMaxValue: null,
  priceRangeMinValue: null,
  priceRangeCurrency: null,
  searchCountTemporaryFilters: {
    priceRange: undefined,
    previousPriceRange: undefined
  }
});

interface GetFilteredProductsThunkInput {
  searchValue?: string;
  filters?: ProductFilters;
}
export interface GetFilteredProductsThunkOutput {
  productsPage: Page<Bag>;
  priceRange: PriceRange;
}

interface PriceRangeMinMaxThunkInput {
  searchValue?: string;
  filters?: Omit<ProductFilters, "priceRange">;
}

export const getFilteredProducts = createAsyncThunk<GetFilteredProductsThunkOutput, GetFilteredProductsThunkInput | undefined, { state: RootState }>(
  "product_filters/getFilteredProducts",
  async (input, { dispatch, getState }) => {
    let filters: ProductFilters | undefined;
    let searchValue: string | undefined;
    if (input?.filters) filters = input.filters;
    if (input?.searchValue) searchValue = input.searchValue;

    const { productFilters } = getState();
    if (input?.searchValue !== productFilters.searchValue) {
      const cleanFilters = {
        airlines: [],
        luggageBrand: [],
        priceRange: undefined,
        reviews: null,
        prime: false,
        bagType: [],
        casing: [],
        material: [],
        color: [],
        size: null,
        volume: [],
        weight: [],
        numberWheels: []
      };
      setFilters(cleanFilters);
    }

    const useCase = await locator.get<() => SearchProductsUseCase>(TYPES.SearchProductsUseCase)();
    const finalFilters = filters || productFilters.filters;
    const { priceRange, previousPriceRangeVal, ...restFinalFilters } = finalFilters;
    const searchPromise = useCase.execute({
      filters: { ...restFinalFilters, priceRange: isEqual(priceRange, previousPriceRangeVal) ? undefined : priceRange },
      searchValue: searchValue || productFilters.searchValue,
      orderBy: productFilters.orderBy,
      page: productFilters.currentPage ?? 1
    });
    dispatch(setPromise(searchPromise));
    return searchPromise;
  }
);

export const getFilteredProductsCount = createAsyncThunk<{ totalCount: number; priceRange: PriceRange }, ProductFilters, { state: RootState }>(
  "product_filters/getFilteredProductsCount",
  async (filters, { dispatch, getState }) => {
    try {
      const { productFilters } = getState();
      const useCase = await locator.get<() => SearchProductsCountUseCase>(TYPES.SearchProductsCountUseCase)();
      const { priceRange, ...restFilters } = filters;

      const searchPromise = useCase.execute({
        filters: {
          ...restFilters,
          priceRange: isEqual(priceRange, productFilters.searchCountTemporaryFilters.previousPriceRange) ? undefined : priceRange
        },
        searchValue: productFilters.searchValue
      });
      dispatch(setCountPromise(searchPromise));
      return searchPromise;
    } catch (e) {
      throw e;
    }
  }
);

export type SetFiltersAndSearchInput = { orderBy?: ProductOrderByEnum } & { filters?: Partial<ProductFilters> } & { searchValue?: string } & {
  page?: number;
};
export const setFiltersAndSearchThunk = createAsyncThunk<void, SetFiltersAndSearchInput, { state: RootState }>(
  "product_filters/setFiltersAndSearch",
  async ({ orderBy, searchValue, filters, page }, { dispatch, getState }) => {
    const { productFilters } = getState();
    if (searchValue) dispatch(setSearchValue(searchValue));
    if (filters) dispatch(setFilters(filters));
    if (orderBy) dispatch(setOrderBy(orderBy));
    if (page) {
      dispatch(setPage(page));
    } else if (searchValue || filters) {
      dispatch(setPage(1));
    }

    debouncedDispatch(getFilteredProducts());
  }
);

const productFiltersSlice = createSlice({
  name: "product_filters.slice",
  initialState: initialState(),
  reducers: {
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setFilters: (state, action: PayloadAction<Partial<Omit<ProductFilters, "previousPriceRangeVal">>>) => {
      state.filters = Object.assign(state.filters, { ...action.payload, previousPriceRangeVal: state.filters.priceRange });
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<ProductOrderByEnum>) => {
      state.orderBy = action.payload;
    },
    setPromise: (state, action: PayloadAction<Promise<any>>) => {
      state.searchPromise = action.payload;
    },
    setCountPromise: (state, action: PayloadAction<Promise<any>>) => {
      state.countSearchPromise = action.payload;
    },
    clearSearchCount: (state) => {
      state.resultCountForFilters = null;
    },
    resetFiltersSlice: (state) => {
      state = Object.assign(state, initialState());
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFilteredProducts.fulfilled, (state, { payload }) => {
      const { priceRange, productsPage } = payload;
      state.productsPage = productsPage;
      state.searchPromise = null;
      state.showEmptyState = productsPage.totalCount === 0 || !productsPage.totalCount;

      if (
        !(state.priceRangeMinValue !== undefined && state.priceRangeMaxValue !== undefined) ||
        isEqual(state.filters.priceRange, state.filters.previousPriceRangeVal)
      ) {
        state.filters.priceRange = priceRange.priceRangeTupleAmount;

        state.priceRangeMinValue = priceRange.priceRangeMinAmount;
        state.priceRangeMaxValue = priceRange.priceRangeMaxAmount;
        state.priceRangeCurrency = priceRange.currency;
      }
    });
    builder.addCase(getFilteredProducts.rejected, (state, { payload }) => {
      state.searchPromise = null;
      state.productsPage = new Page<Bag>(Bag);
      state.showEmptyState = true;

      state.priceRangeMinValue = null;
      state.priceRangeMaxValue = null;
      state.priceRangeCurrency = null;
    });
    builder.addCase(getFilteredProductsCount.fulfilled, (state, { payload, meta }) => {
      const { totalCount, priceRange } = payload;
      state.resultCountForFilters = totalCount;
      state.countSearchPromise = null;

      if (
        !(state.priceRangeMinValue !== undefined && state.priceRangeMaxValue !== undefined) ||
        isEqual(meta.arg.priceRange, state.searchCountTemporaryFilters.previousPriceRange) // compare with sent argument and previous for search count
      ) {
        state.searchCountTemporaryFilters.priceRange = priceRange.priceRangeTupleAmount;

        state.priceRangeMinValue = priceRange.priceRangeMinAmount;
        state.priceRangeMaxValue = priceRange.priceRangeMaxAmount;
        state.priceRangeCurrency = priceRange.currency;
      }

      // once checks or done set previous search value with argument
      state.searchCountTemporaryFilters.previousPriceRange = meta.arg.priceRange;
    });
    builder.addCase(getFilteredProductsCount.rejected, (state, { payload }) => {
      state.countSearchPromise = null;
      state.resultCountForFilters = null;

      state.priceRangeMinValue = null;
      state.priceRangeMaxValue = null;
      state.priceRangeCurrency = null;
    });
  }
});

function productFiltersBase(state: RootState) {
  return state.productFilters;
}

export const getSearchValue = createSelector([productFiltersBase], (slice) => slice.searchValue);
export const getSearchPromise = createSelector([productFiltersBase], (slice) => slice.searchPromise);
export const getFilterValues = createSelector([productFiltersBase], (slice) => slice.filters);
export const getShowEmptyState = createSelector([productFiltersBase], (slice) => slice.showEmptyState);
export const getOrderBy = createSelector([productFiltersBase], (slice) => slice.orderBy);
export const getFlightUseFilterValues = createSelector([getFilterValues], (filters) => ({
  flightUse: filters.flightUse
}));
export const getTotalFiltersApplied = createSelector([getFilterValues], (filters: Record<string, any>) => {
  return Object.keys(filters).reduce((acc, key) => {
    if (Array.isArray(filters[key]) && key !== "priceRange") {
      acc += filters[key].length;
    } else if (key !== "priceRange") {
      acc += filters[key] ? 1 : 0;
    }
    return acc;
  }, 0);
});
export const getTotalPages = createSelector([productFiltersBase], (slice) => {
  const totalPages = Math.ceil((slice.productsPage.totalCount ?? 0) / ITEMS_PER_PAGE_LIMIT);
  return totalPages >= 1 ? totalPages : 1;
});
export const getCurrentPage = createSelector([productFiltersBase], (slice) => slice.currentPage ?? slice.productsPage.page ?? 1);
export const getPriceRangeMax = createSelector([productFiltersBase], (slice) => slice.priceRangeMaxValue ?? undefined);
export const getPriceRangeMin = createSelector([productFiltersBase], (slice) => slice.priceRangeMinValue ?? undefined);
export const getPriceRangeCurrency = createSelector([productFiltersBase], (slice) => slice.priceRangeCurrency);
export const getProductsList = createSelector([productFiltersBase], (slice) => slice.productsPage.items);
export const getProductsListTotalCount = createSelector([productFiltersBase], (slice) => slice.productsPage.totalCount ?? 0);
export const getResultCountForFilters = createSelector([productFiltersBase], (slice) => slice.resultCountForFilters ?? slice.productsPage.totalCount);

export const { setSearchValue, setFilters, setOrderBy, setPromise, setCountPromise, clearSearchCount, resetFiltersSlice, setPage } =
  productFiltersSlice.actions;
export default productFiltersSlice.reducer;
