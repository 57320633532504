import { Expose, Type } from "class-transformer";
import { ImagesCollection } from "@/src/common/domain/models/image";

export class FamilyProductColor {
  @Expose()
  color!: string;
  @Expose()
  @Type(() => ProductColorVariant)
  products!: Array<ProductColorVariant>;
}

export class ProductColorVariant {
  asin!: string;
  @Type(() => ImagesCollection)
  images!: ImagesCollection;
}
