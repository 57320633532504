import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { UiSliceState } from "../view_models/ui.slice";
import { ReactNode } from "react";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import type { AppInitUseCase } from "@/src/common/domain/use_cases/app_init_use_case";
import dayjs from "dayjs";

const initialState = (): UiSliceState => ({
  showLoader: false,
  modal: {
    show: false,
    content: null
  },
  appConfig: null
});

export const showModal = createAsyncThunk<void, ReactNode>("ui/showModal", async (modalContent, { dispatch }) => {
  dispatch(setModalContent(modalContent));
  setTimeout(() => {
    dispatch(setModalShow(true));
  }, 150);
});

export const hideModal = createAsyncThunk<void, ReactNode>("ui/hideModal", async (modalContent, { dispatch }) => {
  dispatch(setModalShow(false));
  setTimeout(() => {
    dispatch(setModalContent(null));
  }, 250);
});

export const initApp = createAsyncThunk("ui/initApp", async () => {
  const useCase = await locator.get<() => AppInitUseCase>(TYPES.AppInitUseCase)();
  return useCase.execute();
});

const uiSlice = createSlice({
  name: "ui.slice",
  initialState: initialState(),
  reducers: {
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setModalShow: (state, action: PayloadAction<boolean>) => {
      state.modal.show = action.payload;
    },
    setModalContent: (state, action: PayloadAction<ReactNode>) => {
      state.modal.content = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initApp.fulfilled, (state, { payload }) => {
      state.appConfig = payload.appConfig;
    });
  }
});

function selectUiBase(state: RootState) {
  return state.ui;
}

export const getShowLoader = createSelector([selectUiBase], (slice) => slice.showLoader);
export const getShowModal = createSelector([selectUiBase], (slice) => slice.modal.show);
export const getModalContent = createSelector([selectUiBase], (slice) => slice.modal.content);
export const getAirlinesLastUpdate = createSelector([selectUiBase], (slice) => (slice.appConfig?.lastAirlineUpdate ?? dayjs()).format("D MMMM YYYY"));

export const { setModalContent, setModalShow, setShowLoader } = uiSlice.actions;
export default uiSlice.reducer;
