import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/src/common/ui/store";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import { ProductSearchState } from "@/src/products/ui/view_models/product_search.slice";
import { SuggestionsRepositoryProvider } from "@/src/common/domain/interfaces/suggestions_repository";

const initialState = (): ProductSearchState => ({
  results: [],
  isMenuResultsOpen: false
});

export const getSuggestions = createAsyncThunk<Array<string>, string>("product_search/getSearchResults", async (searchValue, { dispatch }) => {
  const suggestionsRepository = await locator.get<SuggestionsRepositoryProvider>(TYPES.ISuggestionsRepository)();
  if (searchValue.length) {
    return suggestionsRepository.search(searchValue);
  } else {
    return Promise.resolve([]);
  }
});

const productSearchSlice = createSlice({
  name: "product_search.slice",
  initialState: initialState(),
  reducers: {
    setMenuResultsOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuResultsOpen = action.payload;
    },
    clearSuggestions: (state) => {
      state.results = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSuggestions.fulfilled, (state, { payload }) => {
      state.results = payload;
    });
  }
});

function selectProductSearchBase(state: RootState) {
  return state.productSearch;
}

export const getSuggestionsResults = createSelector([selectProductSearchBase], (productSearch) => productSearch.results);
export const getIsMenuResultsOpen = createSelector([selectProductSearchBase], (productSearch) => productSearch.isMenuResultsOpen);

export const { setMenuResultsOpen, clearSuggestions } = productSearchSlice.actions;
export default productSearchSlice.reducer;
