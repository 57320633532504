import { Type } from "class-transformer";

export class ImageData {
  url!: string;
  width!: number;
  height!: number;
}

export class Image {
  @Type(() => ImageData)
  large!: ImageData;
  @Type(() => ImageData)
  small!: ImageData;
  @Type(() => ImageData)
  medium!: ImageData;

  get smallUrl(): string {
    return this.small.url;
  }
}

export class ImagesCollection {
  @Type(() => Image)
  primary!: Image;
  @Type(() => Image)
  variants: Array<Image> = [];
}
