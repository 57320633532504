import { BagSizes } from "@/src/products/domain/models/bag";
import { Expose, Type } from "class-transformer";
import { Country } from "@/src/common/domain/models/country";

export class Airline {
  id!: number;
  name!: string;
  webpage!: string;
  @Expose({ name: "bag_sizes" })
  bagSizes!: BagSizes;
  @Type(() => Country)
  countries: Array<Country> = [];
  logo!: string;
}
