import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { RouterSliceState } from "@/src/common/ui/view_models/router.slice";

const initialState = (): RouterSliceState => ({
  history: []
});

const routerSlice = createSlice({
  name: "router.slice",
  initialState: initialState(),
  reducers: {
    pushRoute: (state, action: PayloadAction<string>) => {
      state.history.push(action.payload);
    }
  }
});

function selectRouterBase(state: RootState) {
  return state.router;
}

export const getLastRoute = createSelector([selectRouterBase], (slice): string | null =>
  slice.history.length ? slice.history[slice.history.length - 1] : null
);

export const { pushRoute } = routerSlice.actions;
export default routerSlice.reducer;
