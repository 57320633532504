import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/src/common/ui/store";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import { EmptySearchBagState, EmptySearchState } from "@/src/products/ui/view_models/empty_search.slice";
import { IProduct } from "@/src/products/domain/interfaces/product";
import { ProductsRepositoryProvider } from "@/src/products/domain/interfaces/products_repository";
import { BagType } from "@/src/products/domain/models/bag";

const initialState = (): EmptySearchState => ({
  cabinBag: {
    loading: false,
    results: []
  },
  extraBag: {
    loading: false,
    results: []
  },
  smallBag: {
    loading: false,
    results: []
  }
});

const getBagState = (bagType: BagType, state: EmptySearchState): EmptySearchBagState | null => {
  switch (bagType) {
    case BagType.CABIN:
      return state.cabinBag;
    case BagType.EXTRA:
      return state.extraBag;
    case BagType.SMALL:
      return state.smallBag;
    default:
      return null;
  }
};

export const getEmptyResultSuggestions = createAsyncThunk<[BagType, Array<IProduct>] | void, BagType, { state: RootState }>(
  "empty_search/getMostLoved",
  async (bagType, { dispatch, getState }) => {
    const { emptySearch } = getState();
    const bagState = getBagState(bagType, emptySearch);
    if (bagState?.results.length) return; // don't search again if already some bag state results

    dispatch(setLoading({ bagType, loaderState: true }));
    try {
      const suggestionsRepository = await locator.get<ProductsRepositoryProvider>(TYPES.IProductsRepository)();
      const result = await suggestionsRepository.mostLovedBags(bagType);
      return [bagType, result];
    } finally {
      dispatch(setLoading({ bagType, loaderState: false }));
    }
  }
);

const emptySearchSlice = createSlice({
  name: "empty_search.slice",
  initialState: initialState(),
  reducers: {
    setLoading: (state, action: PayloadAction<{ bagType: BagType; loaderState: boolean }>) => {
      const { bagType, loaderState } = action.payload;
      const bagState = getBagState(bagType, state);
      if (bagState) bagState.loading = loaderState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmptyResultSuggestions.fulfilled, (state, { payload }) => {
      if (payload) {
        const [bagType, results] = payload;
        switch (bagType) {
          case BagType.CABIN:
            state.cabinBag.results = results;
            break;
          case BagType.EXTRA:
            state.extraBag.results = results;
            break;
          case BagType.SMALL:
            state.smallBag.results = results;
            break;
          default:
            break;
        }
      }
    });
  }
});

function selectEmptySearchBase(state: RootState) {
  return state.emptySearch;
}

export const getCabinBagResults = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.cabinBag.results);
export const getExtraBagResults = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.extraBag.results);
export const getSmallBagResults = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.smallBag.results);
export const getCabinBagLoading = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.cabinBag.loading);
export const getExtraBagLoading = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.extraBag.loading);
export const getSmallBagLoading = createSelector([selectEmptySearchBase], (emptySearch) => emptySearch.smallBag.loading);

export const { setLoading } = emptySearchSlice.actions;
export default emptySearchSlice.reducer;
