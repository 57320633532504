import { ILocalStorageService } from "@/src/common/domain/interfaces/local_storage_service";
import { injectable } from "inversify";
import { deserialize, serialize } from "class-transformer";
import { ClassConstructor } from "class-transformer/types/interfaces";

@injectable()
export class LocalStorageService implements ILocalStorageService {
  private storage: Storage | null;

  constructor() {
    this.storage = typeof window !== "undefined" ? window.localStorage : null;
  }

  delete(key: string): void {
    this.storage?.removeItem(key);
  }

  get<T>(key: string, classObj?: ClassConstructor<T>): T | null {
    const item = this.storage?.getItem(key);
    if (item) {
      return classObj ? deserialize<T>(classObj, item) : (JSON.parse(item) as T);
    }
    return null;
  }

  put<T>(key: string, value: T): void {
    this.storage?.setItem(key, serialize(value));
  }
}
