export class Dimensions {
  widthCm!: number;
  heightCm!: number;
  lengthCm!: number;
  widthInches!: number;
  heightInches!: number;
  lengthInches!: number;

  get dimensionCmFormatted() {
    return `${Math.round(this.widthCm)} x ${Math.round(this.heightCm)} x ${Math.round(this.lengthCm)} cm`;
  }

  get dimensionInchesFormatted() {
    return `${Math.round(this.widthInches)} x ${Math.round(this.heightInches)} x ${Math.round(this.lengthInches)} inches`;
  }
}
