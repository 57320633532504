import { Middleware } from "@reduxjs/toolkit";
import { PRODUCT_SLICE_NAME } from "@/src/cart/ui/store/products_cart.slice";
import { locator } from "@/ioc/index";
import { TYPES } from "@/ioc/types";
import { RootState } from "@/src/common/ui/store";
import { ICartStorageRepository } from "@/src/cart/domain/interfaces/cart_storage_repository";

export const productCartMiddleware: Middleware = (api) => (next) => async (action) => {
  const result = next(action);
  const { productsCart }: RootState = api.getState();
  if (action.type?.startsWith(`${PRODUCT_SLICE_NAME}/`) && productsCart.initialized) {
    const storageRepository = locator.get<ICartStorageRepository>(TYPES.ICartStorageRepository);
    storageRepository.sync(productsCart);
  }
  return result;
};
