import styles from "./am_modal.module.scss";
import { useAppDispatch, useAppSelector } from "@/src/common/ui/store";
import { getModalContent, getShowModal, hideModal } from "@/src/common/ui/store/ui.slice";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AMIconButton } from "@/src/common/ui/components/am_icon_button/am_icon_button";
import { Close } from "@/src/common/ui/icons";
import { useClickOutside } from "@/src/common/ui/hooks/mouse";

export const AMModal = () => {
  const dispatch = useAppDispatch();
  const [showContent, setShowContent] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const showModal = useAppSelector(getShowModal);
  const modalContent = useAppSelector(getModalContent);

  const escapeKeyUpListener = (e: KeyboardEvent) => {
    if (showModal && (e.key === "Escape" || e.keyCode === 27)) {
      dispatch(hideModal());
    }
  };

  useClickOutside(modalContentRef, (e) => dispatch(hideModal()));

  useEffect(() => {
    document.addEventListener("keyup", escapeKeyUpListener);

    return () => {
      document.removeEventListener("keyup", escapeKeyUpListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      if (modalRef.current) disableBodyScroll(modalRef.current);
      setTimeout(() => {
        setShowContent(true);
      }, 150);
    } else {
      setShowContent(false);
      clearAllBodyScrollLocks();
    }
  }, [showModal]);

  return (
    <CSSTransition in={showModal} timeout={200} classNames="fade-in" mountOnEnter unmountOnExit>
      <div ref={modalRef} className={styles["am-modal"]}>
        <CSSTransition in={showContent} timeout={200} classNames="fade-in" mountOnEnter unmountOnExit>
          <div ref={modalContentRef} className={styles["am-modal__content"]}>
            <AMIconButton
              className={styles["am-modal__close-btn"]}
              icon={<Close />}
              amStyles="am-transparent-round"
              onClick={() => dispatch(hideModal())}
            />
            {modalContent}
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};
