export const TYPES = {
  IHttpClient: Symbol("IHttpClient"),
  ILocalStorageService: Symbol("ILocalStorageService"),
  IEnvVars: Symbol("IEnvVars"),
  // Repositories
  IAirlinesRepository: Symbol("IAirlinesRepository"),
  ICommonsRepository: Symbol("ICommonsRepository"),
  ISuggestionsRepository: Symbol("ISuggestionsRepository"),
  IProductsRepository: Symbol("IProductsRepository"),
  ICartStorageRepository: Symbol("ICartStorageRepository"),
  // Use cases
  GetHomeDataUseCase: Symbol("GetHomeDataUseCase"),
  GetMostLovedUseCase: Symbol("GetMostLovedUseCase"),
  AppInitUseCase: Symbol("AppInitUseCase"),
  GetFiltersUseCase: Symbol("GetFiltersUseCase"),
  SearchProductsUseCase: Symbol("SearchProductsUseCase"),
  SearchProductsCountUseCase: Symbol("SearchProductsCountUseCase"),
  PriceRangeMinMaxUseCase: Symbol("PriceRangeMinMaxUseCase"),
  GetProductDetailUseCase: Symbol("GetProductDetailUseCase")
};
