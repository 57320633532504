import styles from "./am_main_loader.module.scss";
import { useAppSelector } from "@/src/common/ui/store";
import { getShowLoader } from "@/src/common/ui/store/ui.slice";
import { CSSTransition } from "react-transition-group";
import { AMLoader } from "@/src/common/ui/components/am_loader/am_loader";

export const AMMainLoader = () => {
  const showLoader = useAppSelector(getShowLoader);

  return (
    <CSSTransition in={showLoader} timeout={200} classNames="fade-in" mountOnEnter unmountOnExit>
      <div className={styles["am-main-loader"]}>
        <AMLoader />
      </div>
    </CSSTransition>
  );
};
