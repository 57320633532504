import { Exclude, Expose, Type } from "class-transformer";

export class Page<T> {
  @Exclude()
  private type: Function;

  @Expose({ name: "results" })
  @Type((options) => {
    return (options?.newObject as Page<T>).type;
  })
  items: Array<T> = [];
  totalCount?: number;
  page?: number;
  previous?: number;
  next?: number;

  constructor(type: Function) {
    this.type = type;
  }
}
