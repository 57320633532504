import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ui from "./ui.slice";
import router from "./router.slice";
import productSearch from "@/src/products/ui/store/product_search.slice";
import productFilters from "@/src/products/ui/store/product_filters.slice";
import emptySearch from "@/src/products/ui/store/empty_search.slice";
import productsCart, { productCartPreloadState } from "@/src/cart/ui/store/products_cart.slice";
import debounce from "lodash.debounce";
import { productCartMiddleware } from "@/src/cart/ui/store/product_cart.middleware";

export const store = configureStore({
  reducer: {
    ui,
    productSearch,
    productFilters,
    emptySearch,
    productsCart,
    router
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(productCartMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const debouncedDispatch = debounce(store.dispatch, 350);
