import "reflect-metadata";
import { Container } from "inversify";
import { TYPES } from "./types";
import { IHttpClient } from "../domain/interfaces/http_client";
import { IEnvVars } from "../domain/interfaces/env_vars";
import { EnvVars } from "../domain/models/env_vars";
import { HttpClient } from "../data/models/http_client";
import { AirlinesRepositoryProvider, IAirlinesRepository } from "@/src/airlines/domain/interfaces/airlines_repository";
import { bindDynamicModule } from "@/ioc/utils";
import { CommonsRepositoryProvider, ICommonsRepository } from "@/src/common/domain/interfaces/commons_repository";
import { ISuggestionsRepository, SuggestionsRepositoryProvider } from "@/src/common/domain/interfaces/suggestions_repository";
import { IProductsRepository, ProductsRepositoryProvider } from "@/src/products/domain/interfaces/products_repository";
import { ILocalStorageService } from "@/src/common/domain/interfaces/local_storage_service";
import { LocalStorageService } from "@/src/common/data/services/local_storage_service";
import { ICartStorageRepository } from "@/src/cart/domain/interfaces/cart_storage_repository";
import { CartStorageRepository } from "@/src/cart/data/repositories/cart_storage_repository";
import type { GetProductDetailUseCase } from "@/src/products/domain/use_cases/get_product_detail_use_case";
import type { GetHomeDataUseCase } from "@/src/common/domain/use_cases/get_home_data_use_case";
import type { GetFiltersUseCase } from "@/src/products/domain/use_cases/get_filters_use_case";
import type { SearchProductsUseCase } from "@/src/products/domain/use_cases/search_products_use_case";
import type { SearchProductsCountUseCase } from "@/src/products/domain/use_cases/search_products_count_use_case";
import type { PriceRangeMinMaxUseCase } from "@/src/products/domain/use_cases/price_range_min_max_use_case";
import type { AppInitUseCase } from "@/src/common/domain/use_cases/app_init_use_case";
import type { GetMostLovedUseCase } from "@/src/products/domain/use_cases/get_most_loved_use_case";

const locator = new Container();
locator.bind<IEnvVars>(TYPES.IEnvVars).to(EnvVars).inSingletonScope();
locator.bind<IHttpClient>(TYPES.IHttpClient).to(HttpClient);

// Repositories
locator.bind<ICartStorageRepository>(TYPES.ICartStorageRepository).to(CartStorageRepository);
bindDynamicModule<AirlinesRepositoryProvider, IAirlinesRepository>(TYPES.IAirlinesRepository, () =>
  import("../../airlines/data/repositories/airlines_repository").then((module) => module.AirlinesRepository)
);
bindDynamicModule<CommonsRepositoryProvider, ICommonsRepository>(TYPES.ICommonsRepository, () =>
  import("../../common/data/repositories/commons_repository").then((module) => module.CommonsRepository)
);
bindDynamicModule<SuggestionsRepositoryProvider, ISuggestionsRepository>(TYPES.ISuggestionsRepository, () =>
  import("../../common/data/repositories/suggestions_repository").then((module) => module.SuggestionsRepository)
);
bindDynamicModule<ProductsRepositoryProvider, IProductsRepository>(TYPES.IProductsRepository, () =>
  import("../../products/data/repositories/products_repository").then((module) => module.ProductsRepository)
);

// Services
locator.bind<ILocalStorageService>(TYPES.ILocalStorageService).to(LocalStorageService);

// Use cases
bindDynamicModule<() => Promise<GetHomeDataUseCase>, GetHomeDataUseCase>(TYPES.GetHomeDataUseCase, () =>
  import("../../common/domain/use_cases/get_home_data_use_case").then((module) => module.GetHomeDataUseCase)
);
bindDynamicModule<() => Promise<GetMostLovedUseCase>, GetMostLovedUseCase>(TYPES.GetMostLovedUseCase, () =>
  import("../../products/domain/use_cases/get_most_loved_use_case").then((module) => module.GetMostLovedUseCase)
);
bindDynamicModule<() => Promise<AppInitUseCase>, AppInitUseCase>(TYPES.AppInitUseCase, () =>
  import("../../common/domain/use_cases/app_init_use_case").then((module) => module.AppInitUseCase)
);
bindDynamicModule<() => Promise<GetFiltersUseCase>, GetFiltersUseCase>(TYPES.GetFiltersUseCase, () =>
  import("../../products/domain/use_cases/get_filters_use_case").then((module) => module.GetFiltersUseCase)
);
bindDynamicModule<() => Promise<SearchProductsUseCase>, SearchProductsUseCase>(TYPES.SearchProductsUseCase, () =>
  import("../../products/domain/use_cases/search_products_use_case").then((module) => module.SearchProductsUseCase)
);
bindDynamicModule<() => Promise<SearchProductsCountUseCase>, SearchProductsCountUseCase>(TYPES.SearchProductsCountUseCase, () =>
  import("../../products/domain/use_cases/search_products_count_use_case").then((module) => module.SearchProductsCountUseCase)
);
bindDynamicModule<() => Promise<PriceRangeMinMaxUseCase>, PriceRangeMinMaxUseCase>(TYPES.PriceRangeMinMaxUseCase, () =>
  import("../../products/domain/use_cases/price_range_min_max_use_case").then((module) => module.PriceRangeMinMaxUseCase)
);
bindDynamicModule<() => Promise<GetProductDetailUseCase>, GetProductDetailUseCase>(TYPES.GetProductDetailUseCase, () =>
  import("../../products/domain/use_cases/get_product_detail_use_case").then((module) => module.GetProductDetailUseCase)
);

export { locator };
