import { MouseEventHandler } from "react";
import cs from "classnames";
import styles from "./am_icon_button.module.scss";

export interface ButtonProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
  icon?: React.ReactNode;
  amStyles: "am-square" | "am-round" | "am-flight-use" | "am-transparent" | "am-transparent-round";
  className?: string;
}

export const AMIconButton = ({ onClick, amStyles, icon, disabled = false, className }: ButtonProps) => {
  return (
    <button disabled={disabled} className={cs(styles[amStyles], className)} onClick={onClick}>
      {icon}
    </button>
  );
};
